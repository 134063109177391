import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '4484016b-5bc4-4cda-99af-548aaf80b1f2',
    development: '6d30370f-8246-43dd-bac1-856457ae1a89',
  },
}

export default {
  name: {
    nb: 'Salg, service og reiseliv',
    nn: 'Sal, service og reiseliv',
  },
  slug: 'ssr',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: '',
  },
  assets: {
    logo: '/themes/ssr/images/logo.svg',
    favicon: '/themes/ssr/favicon.ico',
  },
  dbok: {
    nb: [
      {
        title: 'Forretningsdrift',
        link: 'https://reader.dbok.no/#/book/6073f9a8669b1300117eb79e',
      },
      {
        title: 'Kultur og samhandling',
        link: 'https://reader.dbok.no/#/book/5fc0ce3fbe08de0012416a5c',
      },
      {
        title: 'Markedsføring og innovasjon',
        link: 'https://reader.dbok.no/#/book/61024dc65a95f60018d8d309',
      },
    ],
    nn: [
      {
        title: 'Forretningsdrift',
        link: 'https://reader.dbok.no/#/book/6093a380484b11001884300f',
      },
      {
        title: 'Kultur og samhandling',
        link: 'https://reader.dbok.no/#/book/601821251b42ea0019dd2451',
      },
      {
        title: 'Marknadsføring og innovasjon',
        link: 'https://reader.dbok.no/#/book/612f606a5fc1a80012122186',
      },
    ],
  },
  styles: [
    '/themes/ssr/css/ssr.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til Salg, service og reiseliv</h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Velkommen til Sal, service og reiseliv</h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      slug: 'ssr',
      label: 'Bokmål',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Fagbegreper',
        to: '/concepts/fagbegreper',
      },
      {
        title: 'Yrkesoppgaver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'Dokumentasjon ',
        to: '/files/dokumentasjon',
      },
      {
        title: 'Lærerens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
      },
    ],
    nn: [
      {
        title: 'Fagomgrep',
        to: '/concepts/fagbegreper',
      },
      {
        title: 'Yrkesoppgåver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
      },
      {
        title: 'Dokumentasjon ',
        to: '/files/dokumentasjon',
      },
      {
        title: 'Lærarens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
      },
    ],
  },
}
